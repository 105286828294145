<template>
  <div>
    <div class="cra-fixed-header">
      <the-header :is-search-form="false" />
      <the-filterbar />
    </div>
    <v-container fluid class="mt-12 cra-max-content cra-content">
      <v-row class="mb-0" justify="space-between"
        ><v-col cols="12" md="8">
          <h2 class="h2-display">Insights dashboard</h2>
        </v-col></v-row
      >
      <v-row class="mb-2" justify="space-between">
        <v-col cols="12" lg="4">
          <v-card class="mb-2 pt-6 pb-1">
            <v-card-text>
              <h3 class="mb-8 px-8">Accounts overview</h3>
              <v-container fluid>
                <v-row class="px-8 mb-0" align="center">
                  <v-col cols="12" sm="6" lg="12" class="mb-6" align="center"
                    ><pie-chart
                      v-if="piePiecesAccountsOverview.length"
                      :pie-pieces="piePiecesAccountsOverview"
                      class-name="cra-piechart-accounts-overview"
                    ></pie-chart>
                    <div v-else>
                      <v-icon class="cra-icon-no-data">$vuetify.icons.noData</v-icon>
                    </div>
                  </v-col>
                  <v-col class="pb-0" cols="12" sm="6" lg="12">
                    <h5
                      class="cra-headline-accountsOverview accountsActive mb-8 cra-insights-bar-link"
                      @click="navigatePage('/accounts')"
                    >
                      <span class="link-text"
                        >{{ getInsights.accountsOverview.accountsActive }} accounts active</span
                      >
                    </h5>
                    <h5
                      class="cra-headline-accountsOverview accountsWatchlist mb-8 cra-insights-bar-link"
                      @click="navigatePage('/watchlist')"
                    >
                      <span class="link-text"
                        >{{ getInsights.accountsOverview.accountsWatchlist }} accounts at
                        watchlist</span
                      >
                    </h5>
                    <h5
                      class="cra-headline-accountsOverview accountsArchived cra-insights-bar-link"
                      @click="navigatePage('/archive')"
                    >
                      <span class="link-text"
                        >{{ getInsights.accountsOverview.accountsArchived }} accounts archived</span
                      >
                    </h5>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right px-0 pb-0">
                    <span class="cra-insights-sum">
                      {{
                        getSum([
                          getInsights.accountsOverview.accountsActive,
                          getInsights.accountsOverview.accountsWatchlist,
                          getInsights.accountsOverview.accountsArchived,
                        ])
                      }}
                      accounts total
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card class="mb-2 pt-6 pb-1">
            <v-card-text>
              <h3 class="mb-8 px-8">Risk level</h3>
              <v-container fluid>
                <v-row align="center" class="px-8 mb-0">
                  <v-col cols="12" sm="6" lg="12" class="mb-6" align="center"
                    ><pie-chart
                      v-if="piePiecesRiskLevel.length"
                      :pie-pieces="piePiecesRiskLevel"
                      class-name="cra-piechart-risk-level"
                    ></pie-chart>
                    <div v-else>
                      <v-icon class="cra-icon-no-data">$vuetify.icons.noData</v-icon>
                    </div></v-col
                  >
                  <v-col cols="12" sm="6" lg="12" class="pb-0">
                    <h5
                      class="cra-headline-riskLevel high mb-8 cra-insights-bar-link"
                      @click="navigateFilterRiskLevels('High')"
                    >
                      <span class="link-text"
                        >{{ getInsights.riskLevel.high }} accounts at high risk</span
                      >
                    </h5>
                    <h5
                      class="cra-headline-riskLevel medium mb-8 cra-insights-bar-link"
                      @click="navigateFilterRiskLevels('Medium')"
                    >
                      <span class="link-text"
                        >{{ getInsights.riskLevel.medium }} accounts at medium risk</span
                      >
                    </h5>
                    <h5
                      class="cra-headline-riskLevel low cra-insights-bar-link"
                      @click="navigateFilterRiskLevels('Low')"
                    >
                      <span class="link-text"
                        >{{ getInsights.riskLevel.low }} accounts at low risk</span
                      >
                    </h5>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right px-0 pb-0">
                    <span class="cra-insights-sum">
                      {{
                        getSum([
                          getInsights.riskLevel.high,
                          getInsights.riskLevel.medium,
                          getInsights.riskLevel.low,
                        ])
                      }}
                      active accounts total
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card class="mb-2 pt-6 pb-1">
            <v-card-text>
              <h3 class="mb-8 px-8">Customer temperature</h3>
              <v-container fluid>
                <v-row align="center" class="px-8">
                  <v-col cols="12" sm="6" lg="12">
                    <div
                      v-if="
                        getInsights.customerTemperature.frustrated.length ||
                        getInsights.customerTemperature.neutral.length ||
                        getInsights.customerTemperature.satisfied
                      "
                      class="cra-barchart-customer-temperature mb-6"
                    >
                      <div class="bar frustrated">
                        <div
                          class="bar-inner"
                          :style="getBarchartHigh(getInsights.customerTemperature.frustrated)"
                        ></div>
                        <div class="bar-legend"><p class="p-tiny">frustrated</p></div>
                      </div>
                      <div class="bar neutral">
                        <div
                          class="bar-inner"
                          :style="getBarchartHigh(getInsights.customerTemperature.neutral)"
                        ></div>
                        <div class="bar-legend"><p class="p-tiny">neutral</p></div>
                      </div>
                      <div class="bar satisfied">
                        <div
                          class="bar-inner"
                          :style="getBarchartHigh(getInsights.customerTemperature.satisfied)"
                        ></div>
                        <div class="bar-legend"><p class="p-tiny">satisfied</p></div>
                      </div>
                    </div>
                    <div v-else class="mb-6 text-center">
                      <v-icon class="cra-icon-no-data">$vuetify.icons.noData</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" lg="12" class="pb-0">
                    <h5
                      class="cra-headline-customer-temperature frustrated mb-8 cra-insights-bar-link"
                      @click="navigateFilterCustomerTemperatures('Frustrated')"
                    >
                      <span class="link-text"
                        >{{ getInsights.customerTemperature.frustrated }} customers frustrated</span
                      >
                    </h5>
                    <h5
                      class="cra-headline-customer-temperature neutral mb-8 cra-insights-bar-link"
                      @click="navigateFilterCustomerTemperatures('Neutral')"
                    >
                      <span class="link-text"
                        >{{ getInsights.customerTemperature.neutral }} customers neutral</span
                      >
                    </h5>
                    <h5
                      class="cra-headline-customer-temperature satisfied mb-3 cra-insights-bar-link"
                      @click="navigateFilterCustomerTemperatures('Satisfied')"
                    >
                      <span class="link-text"
                        >{{ getInsights.customerTemperature.satisfied }} customers satisfied</span
                      >
                    </h5>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right px-0 pb-0">
                    <span class="cra-insights-sum">
                      {{
                        getSum([
                          getInsights.customerTemperature.frustrated,
                          getInsights.customerTemperature.neutral,
                          getInsights.customerTemperature.satisfied,
                        ])
                      }}
                      active accounts total
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mb-16 pb-8" justify="space-between">
        <v-col cols="12" lg="6">
          <v-card class="mb-2 px-8 pt-6 pb-1">
            <v-card-text>
              <h3 class="mb-8">Risk development 30 days</h3>
              <div class="cra-insights-bar h5 mb-8">
                <v-icon class="icon" large>$vuetify.icons.arrowUpRight</v-icon>
                <div class="counter">{{ getInsights.riskDevelopment.favorably }}</div>
                <div class="text">Accounts developed favorably</div>
              </div>
              <div class="cra-insights-bar h5 mb-8">
                <v-icon class="icon" large>$vuetify.icons.warning</v-icon>
                <div class="counter">{{ getInsights.riskDevelopment.unfavorably }}</div>
                <div class="text">Accounts developed unfavorably</div>
              </div>
              <div class="cra-insights-bar h5 mb-8">
                <v-icon class="icon" large>$vuetify.icons.minus</v-icon>
                <div class="counter">{{ getInsights.riskDevelopment.unchanged }}</div>
                <div class="text">Accounts with unchanged risk</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card class="mb-2 px-8 pt-6 pb-1">
            <v-card-text>
              <h3 class="mb-8">Recent activities 30 days</h3>
              <div class="cra-insights-bar h5 mb-8">
                <v-icon class="icon" large>$vuetify.icons.settingsCheck</v-icon>
                <div class="counter">{{ getInsights.recentActivities.accountsUpdated }}</div>
                <div class="text">Accounts updated</div>
              </div>
              <div class="cra-insights-bar h5 mb-8">
                <v-icon class="icon" large>$vuetify.icons.plus</v-icon>
                <div class="counter">{{ getInsights.recentActivities.accountsAdded }}</div>
                <div class="text">New accounts added</div>
              </div>
              <div class="cra-insights-bar h5 mb-8">
                <v-icon class="icon" large>$vuetify.icons.star</v-icon>
                <div class="counter">{{ getInsights.recentActivities.accountsRemoved }}</div>
                <div class="text">Accounts removed</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <the-footer />
  </div>
</template>
<script>
import TheHeader from '../components/layout/TheHeader';
import TheFooter from '../components/layout/TheFooter';
import TheFilterbar from '../components/layout/TheFilterbar';
import { mapGetters } from 'vuex';
import PieChart from '../components/ui/PieChart';
export default {
  name: 'Insights',
  components: {
    PieChart,
    TheHeader,
    TheFilterbar,
    TheFooter,
  },
  data() {
    return {
      piePiecesRiskLevel: [],
      piePiecesAccountsOverview: [],
    };
  },
  computed: {
    ...mapGetters({
      getInsights: 'insights/getFilteredInsights',
    }),
  },

  watch: {
    getInsights() {
      this.setPieChartRiskLevel();
      this.setPieChartAccountsOverview();
    },
  },
  created() {
    this.setPieChartRiskLevel();
    this.setPieChartAccountsOverview();
  },
  methods: {
    setPieChartRiskLevel() {
      this.piePiecesRiskLevel = [];
      Object.keys(this.getInsights.riskLevel).forEach((key) => {
        if (this.getInsights.riskLevel[key] > 0) {
          const piece = {
            type: key,
            value: this.getInsights.riskLevel[key],
          };
          this.piePiecesRiskLevel.push(piece);
        }
      });
    },
    setPieChartAccountsOverview() {
      this.piePiecesAccountsOverview = [];
      Object.keys(this.getInsights.accountsOverview).forEach((key) => {
        if (this.getInsights.accountsOverview[key] > 0) {
          const piece = {
            type: key,
            value: this.getInsights.accountsOverview[key],
          };
          this.piePiecesAccountsOverview.push(piece);
        }
      });
    },
    getBarchartHigh(value) {
      const allValues =
        this.getInsights.customerTemperature.frustrated +
        this.getInsights.customerTemperature.neutral +
        this.getInsights.customerTemperature.satisfied;
      return 'height: ' + (value / allValues) * 100 + '%';
    },
    getSum(valueArray) {
      const reducer = (accumulator, curr) => accumulator + curr;
      return valueArray.reduce(reducer);
    },
    resetFilter(filter) {
      this.$store.commit('filters/setFilter', {
        fieldName: filter,
        value: [],
      });
    },
    navigateFilter(filter, filterValue) {
      this.$store.commit('filters/setFilter', {
        fieldName: filter,
        value: [filterValue],
      });
      this.$router.push('/accounts');
    },
    navigateFilterRiskLevels(filterValue) {
      this.resetFilter('customerTemperatures');
      this.navigateFilter('riskLevels', filterValue);
    },
    navigateFilterCustomerTemperatures(filterValue) {
      this.resetFilter('riskLevels');
      this.navigateFilter('customerTemperatures', filterValue);
    },
    navigatePage(page) {
      this.resetFilter('customerTemperatures');
      this.resetFilter('riskLevels');
      this.$router.push(page);
    },
  },
};
</script>
